<template>
    <div class="diagnose xi">
        <hd-com></hd-com>
        <div class="banner">
            <div class="banner-text">
                <h3>检查诊断</h3>
                <p>当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><router-link to="/diagnose/Diagnose">检查诊断</router-link><i class="el-icon-arrow-right"></i>
                <a v-if=" ExamineDetail">{{ExamineDetail.examineName}}
                </a>
                <!-- <a v-else-if="template">{{theme}}</a>-->
                </p>
            </div>
        </div>
        <div class="content-wrap">
            <div class="content-top">
                <ul class="diagnose-tab">
                    <li><router-link to="/diagnose/Diagnose">症状诊断</router-link></li>
                    <li class="active"><router-link to="/diagnose/Examine">检查诊断</router-link></li>
                    <li><router-link to="/diagnose/Inspection">检验检测</router-link></li>
                </ul>
            </div>
            <div class="content">
                <div v-if="ExamineDetail">
                    <el-scrollbar class="left-nav-wrap" style="height: 550px;">
                        <!-- 具体要包裹的内容 -->
                        <div class="left-nav">
                            <!-- <h3 v-if="ExamineDetail">{{ExamineDetail.data.text}}</h3> -->
                            <ul class="nav-box">
                                <li v-for="(childNavMenu,index)  in ExamineDetail.examineInfos"
                                :key="index"
                                @click="jump(index)"
                                :class="index==0?'active':''">
                                    <span></span>{{childNavMenu.title}}
                                    <!-- <div class="left-sub-nav" v-for="childSubmenu in childNavMenu.children">
                                        <p v-if="childSubmenu.data">{{childSubmenu.data.text}}</p>
                                    </div> -->
                                </li>
                            </ul>
                        </div>
                    </el-scrollbar>
                    <div class="ny_container">
                        <h2 class="ny-right-tit" v-if="ExamineDetail">{{ExamineDetail.examineName}}</h2>
                        <!-- <p class="ny-right-info" v-if="ExamineDetail" v-html="ExamineDetail.data.info"></p> -->
                        <ul class="ny-list">
                            <li class="ny-item" v-for="(childNavMenu,index) in ExamineDetail.examineInfos" :key="index">
                                <h3 v-if="childNavMenu.title"><span></span>{{childNavMenu.title}}</h3>
                                <div class="ny-mes">
                                    <p v-if="childNavMenu.info" v-html="childNavMenu.info"></p>
                                    <!-- <div class="ny-item-child" v-for="childSubmenu in childNavMenu.children">
                                        <h4 v-if="childSubmenu.data"><span></span>{{childSubmenu.data.text}}</h4>
                                        <div class="ny-mes">
                                            <p v-if="childSubmenu.data" v-html="childSubmenu.data.info"></p>
                                        </div>
                                    </div> -->

                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <div v-else-if="template">
                    <h3 class="ny-right-tit">{{theme}}</h3>
                    <img :src="template" style="display: block;margin: 0 auto;" alt="">
                </div> -->
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
    import { Examine } from "../../components/Disease/Examine"
    import hdCom from '../../components/header'
    import ftCom from '../../components/footer'
    import rtBar from "../../components/right";
    export default {
        name: "ExamDetail",
        components: {
            hdCom,
            ftCom,
            rtBar
        },
        data() {
            var examine = new Examine(this.TokenClient, this.Services.Disease)
            return {
                id: this.$route.query.id,   //逻辑症状id
                ExamineDetail: {}, //初始化详情
                template: {},//图片路径
                theme: '',
                examineDomain: examine
            }
        },
        methods: {
            handleScroll() {
                let scrollY = window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                let scrollObj = document.querySelector(".left-nav-wrap");
                let banner = document.querySelector(".banner");
                //scrollObj 这个是下图右边答题卡的div

                if (scrollY > 80) {
                    banner.style.position = "fixed";
                    banner.style.top = 0;
                    banner.style.zIndex = 99;
                    scrollObj.style.position = "fixed";
                    scrollObj.style.top = "140px";
                } else {
                    banner.style.position = "initial";
                    banner.style.top = "none";
                    scrollObj.style.position = "absolute";
                    scrollObj.style.top = "auto";
                }
            },
            //详情获取
            getExamineDetail: function () {
                let params = `${this.id}`
                var _this = this;
                _this.examineDomain.Detail(params, function (data) {
                    console.log(data.data)
                    _this.ExamineDetail = (data.data)
                    // if (data.data.template.indexOf('.jpg') != -1) {
                    //     _this.template = data.data.template
                    //     _this.theme = data.data.theme
                    // }
                },
                    function (error) {
                        console.log('检查逻辑图请求异常!请刷新重试。', error)
                    })
            },
             dataScroll: function () {
                this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
            },
            jump(index) {
                let jump = document.getElementsByClassName('ny-item');
                // 获取需要滚动的距离
                let total = jump[index].offsetTop -100;
                window.scroll(0, total)
                // Chrome
                //document.body.scrollTop = total;
                // Firefox
                //document.documentElement.scrollTop = total;
                // Safari
                //window.pageYOffset = total;
                // $('html, body').animate({
                // 'scrollTop': total
                // }, 400);

            },
            loadSroll: function () {
                var self = this;
                var $navs = $(".nav-box li");
                var sections = document.getElementsByClassName('ny-item');
                for (var i = sections.length - 1; i >= 0; i--) {
                    if (self.scroll >= sections[i].offsetTop -100 ) {
                        $navs.eq(i).addClass("active").siblings().removeClass("active")
                        break;
                    }
                }
            }
        },
          watch: {
            scroll: function () {
                this.loadSroll()
            }
        },
        mounted() {
            window.addEventListener("scroll", this.handleScroll);
            window.addEventListener('scroll', this.dataScroll);
            this.getExamineDetail();
        },
        destroyed() {
            //销毁监听
            window.removeEventListener("scroll", this.handleScroll);
            window.removeEventListener('scroll', this.dataScroll);
        },
    }
</script>

<style scoped>
    @import "../../assets/css/header.css";
    @import "../../assets/css/footer.css";

    .banner {
        width: 100%;
        height: 116px;
        background: url("../../assets/img/jbk_banner.png")center no-repeat;
    }

    .banner-text {
        width: 1200px;
        margin: 0 auto;
    }

        .banner-text h3 {
            padding-top: 25px;
            font-size: 26px;
            font-weight: normal;
            color: #ffffff;
        }

        .banner-text p {
            font-size: 18px;
            color: #ffffff;
        }

            .banner-text p a {
                color: #ffffff;
                font-size: 14px;
                margin: 0 5px;
            }

    .content-wrap {
        background: #fcfcfc;
    }

    .content-top {
        height: 64px;
        background: #FFFFFF;
        box-shadow: 2px 0 5px rgba(102, 102, 102, 0.08);
    }

    .diagnose-tab {
        width: 1200px;
        margin: 0 auto;
    }

        .diagnose-tab li, .diagnose-tab li a {
            margin-right: 20px;
            display: inline-block;
            line-height: 63px;
            color: #808080;
            font-size: 16px;
        }

            .diagnose-tab li.active a {
                color: #1fb7a6;
                border-bottom: 2px solid #1fb7a6;
            }

    .content {
        position: relative;
        width: 1200px;
        background: none;
        padding: 30px 0;
        margin: 0 auto;
    }

    .left-nav-wrap {
        position: absolute;
        width: 260px;
        max-height: 690px;
    }

    .left-nav {
        width: 100%;
        background: #FFFFFF;
        padding: 20px 0;
        box-sizing: border-box;
        box-shadow: 5px 0 8px rgba(224, 224, 224, 0.3);
    }

        .left-nav > h3 {
            padding-left: 20px;
            font-size: 18px;
            font-weight: normal;
        }

        .left-nav > ul > li {
            line-height: 48px;
            font-size: 16px;
            color: #666666;
        }

            .left-nav > ul > li span {
                display: inline-block;
                width: 20px;
                height: 8px;
                margin-right: 20px;
            }

            .left-nav > ul > li.active {
                color: #333333;
            }

                .left-nav > ul > li.active span {
                    background: #03b29e;
                }

    .left-sub-nav {
        margin-left: 45px;
    }

        .left-sub-nav p {
            color: #666666;
            font-size: 14px;
            line-height: 30px;
        }

    .ny_container {
        padding: 10px 0;
        margin-left: 280px;
        min-height: 690px;
        background: #FFFFFF;
        box-shadow: 3px 0 5px rgba(224, 224, 224, 0.3);
    }

    .ny-right-tit {
        text-align: center;
        margin: 10px 0;
        font-weight: normal;
        font-size: 24px;
    }

    .ny-right-info {
        padding: 0 30px;
        color: #454545;
        font-size: 15px;
        line-height: 30px;
    }

    .ny-list .ny-item h3 {
        font-size: 18px;
        padding: 15px 0;
    }

        .ny-list .ny-item h3 span {
            display: inline-block;
            width: 20px;
            height: 8px;
            margin-right: 20px;
            background: #1fb7a6;
        }

    .ny-list .ny-item .ny-mes {
        padding: 0 40px;
    }

    .ny-list .ny-item .ny-mes p {
        text-indent: 2em;
        color: #333333 !important;
        font-size: 16px !important;
        line-height: 28px !important;
        margin-bottom: 10px;
    }


    .ny-item-child h4 {
        list-style: circle;
        font-weight: 700;
        color: #333333;
        margin: 10px 0;
    }

    .ny-list .ny-item h4 span {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        border-radius: 50%;
        background: #1fb7a6;
    }
</style>
<style>
    .diagnose .bar-list .yysc{
        display: none !important;
    }
    .el-scrollbar__wrap {
        overflow: auto;
    }
    .diagnose .ny-list .ny-item .ny-mes p span{
        color: #333333 !important;
        font-size: 16px !important;
        line-height: 28px !important;
    }
</style>
